// src/services/pricingService.ts

import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Pobieranie listy cenników dla wybranego typu komponentu
export const fetchPricingByComponentType = async (componentTypeId: number) => {
    const response = await axios.get(`${API_BASE_URL}/component-type-pricing/byComponentTypeId/${componentTypeId}`);
    return response.data;
};

// Dodawanie nowego cennika komponentu
export const createComponentPricing = async (payload: {
    componentTypeId: number;
    packingPrice : number;
    selectionPrice: number;
    scrappingPrice: number;
    dateFrom: string;
}) => {
    const response = await axios.post(`${API_BASE_URL}/component-type-pricing`, payload);
    return response.data; // zakładamy, że backend zwraca utworzony obiekt
};

// Usuwanie cennika komponentu
export const removeComponentPricingById = async (componentPricingId: number) => {
    const response = await axios.delete(`${API_BASE_URL}/component-type-pricing/${componentPricingId}`);
    return response.data;
};