// src/services/pricingService.ts

import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Pobieranie listy typów komponentów
export const fetchComponentTypes = async () => {
    const response = await axios.get(`${API_BASE_URL}/component-types`);
    return response.data;
};

// Pobieranie listy cenników dla wybranego typu komponentu
export const fetchPricingByComponentType = async (componentTypeId: number) => {
    const response = await axios.get(`${API_BASE_URL}/component-type-pricing/byComponentTypeId/${componentTypeId}`);
    return response.data;
};

// Pobieranie podkomponentów dla danego typu komponentu
export const fetchSubcomponentsByComponentType = async (componentTypeId: number) => {
    const response = await axios.get(`${API_BASE_URL}/subcomponents/${componentTypeId}`);
    return response.data;
};

// Pobieranie cenników podkomponentów według typu komponentu
export const fetchPricingBySubcomponentType = async (componentTypeId: number) => {
    const response = await axios.get(`${API_BASE_URL}/subcomponent-pricing/byComponentTypeId/${componentTypeId}`);
    return response.data;
};

// Dodawanie nowego cennika komponentu
export const createComponentPricing = async (payload: {
    componentTypeId: number;
    packingPrice : number;
    selectionPrice: number;
    scrappingPrice: number;
    dateFrom: string;
}) => {
    const response = await axios.post(`${API_BASE_URL}/component-type-pricing`, payload);
    return response.data; // zakładamy, że backend zwraca utworzony obiekt
};

// Dodawanie nowego cennika podkomponentu
export const createSubcomponentPricing = async (payload: {
    subcomponentId: number;
    repairPrice: number;
    isDiscounted: boolean;
    dateFrom: string;
}) => {
    const response = await axios.post(`${API_BASE_URL}/subcomponent-pricing`, payload);
    return response.data;  // zwróci świeżo utworzony obiekt
};

// Pobieranie cenników podkomponentów według typu komponentu
export const fetchPricingListByComponentTypeId = async (componentTypeId: number) => {
    const response = await axios.get(`${API_BASE_URL}/pricing/byComponentTypeId/${componentTypeId}`);
    return response.data;
};

// Pobieranie detali wyceny
export const fetchValuation = async (valuationId: number) => {
    const response = await axios.get(`${API_BASE_URL}/pricing/${valuationId}`);
    return response.data;
};

// Tworzenie nowej wyceny
export const createValuation = async ({
    componentTypeId,
    dateFrom,
    dateTo,
}: {
    componentTypeId: number;
    dateFrom: string;
    dateTo: string;
}) => {
    const response = await axios.post(`${API_BASE_URL}/pricing`, {
        componentTypeId,
        dateFrom,
        dateTo,
    });
    return response.data;
};

// Usuwanie cennika komponentu
export const removeComponentPricingById = async (componentPricingId: number) => {
    const response = await axios.delete(`${API_BASE_URL}/component-type-pricing/${componentPricingId}`);
    return response.data;
};

// Usuwanie cennika podkomponentu
export const removeSubcomponentPricingById = async (subcomponentPricingId: number) => {
    const response = await axios.delete(`${API_BASE_URL}/subcomponent-pricing/${subcomponentPricingId}`);
    return response.data;
};

// Usuwanie cennika wyceny
export const removeValuation = async (valuationId: number) => {
    const response = await axios.delete(`${API_BASE_URL}/pricing/${valuationId}`);
    return response.data;
};

// Pobieranie excela
export const loadValuationDetailsExcel = async (valuationId: number) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/pricing/${valuationId}/excel`, {
            responseType: 'arraybuffer', // Ustawienie odpowiedzi jako binarnych danych
        });

        // Tworzenie obiektu Blob z danych binarnych
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Tworzenie linku do pobrania
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `Wycena_${valuationId}.xlsx`; // Nazwa pliku
        link.click();

        // Czyszczenie obiektu URL
        window.URL.revokeObjectURL(link.href);
    } catch (error) {
        console.error('Błąd podczas pobierania pliku Excel:', error);
    }
};