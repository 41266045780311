// src/context/PricingContext.tsx

import { useNotification } from 'contexts/notification-provider/NotificationProvider';

import React, { createContext, useContext, useState, useEffect } from 'react';
import {
    fetchComponentTypes,
    fetchPricingListByComponentTypeId,
    fetchValuation
} from '../services/pricingService';

import {
    ComponentType,
    ValuationsListByComponentType,
    ValuationDetails
} from '../types/pricingTypes';

interface PricingContextProps {
    componentTypes: ComponentType[];
    selectedComponentType: number | null;
    valuationsListByComponentType: ValuationsListByComponentType[];
    valuationDetailsById: ValuationDetails;
    setValuationDetailsById: (valuationDetails: any) => any;
    setSelectedComponentType: (id: number) => void;
    loadPricingListByComponentTypeId: (componentTypeId: number) => Promise<void>;
    loadValuationDetails: (valuationId: number, dateFrom: string, dateTo: string) => Promise<void>;
    showConfirmation: boolean;
    setShowConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
    confirmationMessage: string;
    setConfirmationMessage: React.Dispatch<React.SetStateAction<string>>;
    onConfirm: (() => void) | null;
    setOnConfirm: React.Dispatch<React.SetStateAction<(() => void) | null>>;
    onCancel: (() => void) | null;
    setOnCancel: React.Dispatch<React.SetStateAction<(() => void) | null>>;
    awaitConfirmation: () => Promise<boolean>;
    loading: boolean;
}

// Inicjalizacja kontekstu
const PricingContext = createContext<PricingContextProps | undefined>(undefined);

export const PricingProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [componentTypes, setComponentTypes] = useState<ComponentType[]>([]);
    const [selectedComponentType, setSelectedComponentType] = useState<number | null>(null);
    const [valuationsListByComponentType, setValuationsListByComponentType] = useState<ValuationsListByComponentType[]>([])
    const [valuationDetailsById, setValuationDetailsById] = useState<ValuationDetails>()
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [onConfirm, setOnConfirm] = useState(null);
    const [onCancel, setOnCancel] = useState(null);

    const [loading, setLoading] = useState<boolean>(false);
    // Wywołanie hooka do powiadomień
    const { addNotification } = useNotification(); // Wywołaj hook tutaj
    // Ładowanie listy typów komponentów na start
    useEffect(() => {
        const loadComponentTypes = async () => {
            setLoading(true);
            try {
                const data = await fetchComponentTypes();
                setComponentTypes(data);
            } catch (error) {
                console.error('Błąd podczas pobierania typów komponentów:', error);
            } finally {
                setLoading(false);
            }
        };

        loadComponentTypes();
    }, []);

    const awaitConfirmation = (): Promise<boolean> => {
        return new Promise((resolve) => {
            const handleConfirm = () => {
                setShowConfirmation(false);
                resolve(true); // Rozwiąż obietnicę jako "true"
            };

            const handleCancel = () => {
                setShowConfirmation(false);
                resolve(false); // Rozwiąż obietnicę jako "false"
            };

            setConfirmationMessage('Czy na pewno chcesz usunąć ten cennik?');
            setShowConfirmation(true);

            // Ustaw callbacki globalnie (opcjonalnie możesz zarządzać w stanie lokalnym)
            setOnConfirm(() => handleConfirm);
            setOnCancel(() => handleCancel);
        });
    };

    // Funkcja do pobierania wycen
    const loadPricingListByComponentTypeId = async (componentTypeId: number) => {
        if (selectedComponentType === null) return;
        setLoading(true);
        try {
            const data = await fetchPricingListByComponentTypeId(componentTypeId);
            setValuationsListByComponentType(data);
        } catch (error) {
            console.error('Błąd podczas pobierania cenników podkomponentów:', error);
        } finally {
            setLoading(false);
        }
    };

    // Funkcja do pobierania detali wyceny
    const loadValuationDetails = async (valuationId: number, dateFrom: string, dateTo: string) => {
        setLoading(true);


        try {
            const data = await fetchValuation(valuationId);
            // Połączenie danych
            const mergedData = {
                ...data,
                id: valuationId,
                dateFrom,
                dateTo,
            };
            setValuationDetailsById(mergedData);
        } catch (error) {
            console.error('Błąd podczas pobierania cenników podkomponentów:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <PricingContext.Provider
            value={{
                componentTypes,
                selectedComponentType,
                valuationsListByComponentType,
                valuationDetailsById,
                setValuationDetailsById,
                setSelectedComponentType,
                loadPricingListByComponentTypeId,
                loadValuationDetails,
                showConfirmation,
                setShowConfirmation,
                confirmationMessage,
                setConfirmationMessage,
                onConfirm,
                setOnConfirm,
                onCancel,
                setOnCancel,
                awaitConfirmation,
                loading,
            }}
        >
            {children}
        </PricingContext.Provider>
    );
};

export const usePricingContext = () => {
    const context = useContext(PricingContext);
    if (!context) {
        throw new Error('usePricingContext musi być użyty wewnątrz PricingProvider');
    }
    return context;
};
