import React, { useEffect, useState } from 'react';
import { usePricingContext } from '../context/PricingContext';
import formatDate from 'utils/dateUtils';

// Import stylów z SCSS
import styles from '../styles.module.scss';
import StandardButton from 'components/shared/standard-button/StandardButton';
import { createValuation, removeValuation, loadValuationDetailsExcel } from '../services/pricingService';

const ValuationsList: React.FC = () => {

    const {
        selectedComponentType,
        awaitConfirmation,
        loadPricingListByComponentTypeId,
        valuationsListByComponentType,
        loadValuationDetails,
        valuationDetailsById,
        setValuationDetailsById,
    } = usePricingContext();

    // Po wybraniu typu komponentu, wczytujemy cenniki
    useEffect(() => {
        if (selectedComponentType !== null) {
            loadPricingListByComponentTypeId(selectedComponentType);
        }
    }, [selectedComponentType]);

    const [isGenerating, setIsGenerating] = useState(false); // Kontroluje widoczność okienka formularza
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    // Funkcja do obsługi generowania wyceny
    const handleGenerateValuation = async () => {
        if (!selectedComponentType || !dateFrom || !dateTo) {
            alert('Wypełnij wszystkie pola!');
            return;
        }

        setIsLoading(true);

        try {
            // Wywołanie funkcji fetchValuation z odpowiednimi parametrami
            const response = await createValuation({
                componentTypeId: selectedComponentType,
                dateFrom,
                dateTo,
            });

            setIsGenerating(false); // Zamknięcie modalnego okna po sukcesie
        } catch (error) {
            console.error('Błąd podczas generowania wyceny:', error);
            alert('Wystąpił błąd podczas generowania wyceny.');
        } finally {
            setIsLoading(false); // Wyłączenie stanu ładowania
            loadPricingListByComponentTypeId(selectedComponentType);
        }
    };

    const deleteValuationById = async (valuationId) => {

        // Wywołaj potwierdzenie z głównego kontekstu
        const userConfirmed = await awaitConfirmation();

        if (!userConfirmed) {
            // Jeśli użytkownik kliknął "NIE", przerwij działanie
            return;
        }

        try {
            // Wywołanie funkcji fetchValuation z odpowiednimi parametrami
            await removeValuation(valuationId)
        } catch (error) {
            console.error('Błąd podczas usuwania wyceny:', error);
            alert('Wystąpił błąd podczas generowania wyceny.');
        } finally {
            setIsLoading(false); // Wyłączenie stanu ładowania
            loadPricingListByComponentTypeId(selectedComponentType);
            setValuationDetailsById(null);
        }
    }

    const getExcel = async (valuationId) => {

        try {
            // Wywołanie funkcji fetchValuation z odpowiednimi parametrami
            await loadValuationDetailsExcel(valuationId)
        } catch (error) {
            alert('Błąd podczas pobierania pliku.');
        } finally {
            setIsLoading(false); // Wyłączenie stanu ładowania
        }
    }

    return (
        <div>
            <h2>Lista Wycen</h2>
            <StandardButton label="Generuj wycenę" onClick={() => setIsGenerating(true)} />
            {/* Modal dla generowania wyceny */}
            {isGenerating && (
                <div className={styles.modalOverlay}>
                    <div className={styles.modalContent}>
                        <button
                            className={styles.closeButton}
                            onClick={() => setIsGenerating(false)}
                        >
                            X
                        </button>
                        <h3>Generuj wycenę</h3>
                        <div className={styles.form}>
                            <div className={styles.formGroup}>
                                <label>Data od:</label>
                                <input
                                    type="date"
                                    value={dateFrom}
                                    onChange={(e) => setDateFrom(e.target.value)}
                                />
                            </div>
                            <div className={styles.formGroup}>
                                <label>Data do:</label>
                                <input
                                    type="date"
                                    value={dateTo}
                                    onChange={(e) => setDateTo(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className={styles.buttonGroup}>
                            <StandardButton
                                label='Generuj'
                                onClick={handleGenerateValuation}
                                disabled={isLoading} // Przycisk wyłączony podczas ładowania
                            />
                            <StandardButton
                                label="Anuluj"
                                variant="secondary"
                                onClick={() => setIsGenerating(false)}
                            />
                        </div>
                    </div>
                </div>
            )}

            {valuationsListByComponentType && valuationsListByComponentType.length > 0 ? (
                <ul>
                    {valuationsListByComponentType.map((valuation: any) => (
                        <li
                            key={valuation.id}
                            style={{
                                cursor: 'pointer',
                                padding: '10px',
                                borderBottom: '1px solid #ccc',
                            }}
                            onClick={() =>
                                loadValuationDetails(
                                    valuation.id,
                                    valuation.dateFrom,
                                    valuation.dateTo
                                )
                            }
                        >
                            <strong>ID:</strong> {valuation.id} | <strong>Typ komponentu:</strong> {valuation.componentTypeId} | <strong>Koszt:</strong> {valuation.totalCost} | <strong>Data od:</strong> {formatDate(valuation.dateFrom)} | <strong>Data do:</strong> {formatDate(valuation.dateTo)}
                        </li>
                    ))}
                </ul>
            ) : (
                <p>Brak wycen dla wybranego typu komponentu.</p>
            )}

            {valuationDetailsById && (
                <div className={styles.modalOverlay}>
                    <div className={styles.modalContent}>
                        <button className={styles.closeButton} onClick={() => setValuationDetailsById(null)}>X</button>
                        <h3>Szczegóły wyceny</h3>
                        <p><strong>Koszt całkowity:</strong> {valuationDetailsById.totalCost} zł</p>
                        <p><strong>Data od:</strong> {formatDate(valuationDetailsById.dateFrom)}</p>
                        <p><strong>Data do:</strong> {formatDate(valuationDetailsById.dateTo)}</p>

                        <StandardButton
                            label='Usuń wycenę'
                            onClick={() => deleteValuationById(valuationDetailsById.id)}
                            disabled={isLoading} // Przycisk wyłączony podczas ładowania
                        />

                        <StandardButton
                            label={isLoading ? 'Generowanie pliku...' : 'Generuj Excel'}
                            onClick={() => getExcel(valuationDetailsById.id)}
                            disabled={isLoading} // Przycisk wyłączony podczas ładowania
                        />

                        {valuationDetailsById.body && valuationDetailsById.body.orders && (
                            <div className={styles.scrollableContainer}>
                                {valuationDetailsById.body.orders.map((order: any, orderIndex: number) => (
                                    <div key={orderIndex} className={styles.orderSection}>
                                        {/* Pola tekstowe dla zamówienia */}
                                        <div className={styles.orderDetails}>
                                            <p><strong>Zamówienie:</strong> {order.outsideNumber}</p>
                                            <p><strong>Wycena:</strong> {order.orderPrice} zł</p>
                                        </div>

                                        {/* Tabela szczegółów komponentów */}
                                        <table className={styles.componentTable}>
                                            <thead>
                                                <tr>
                                                    <th>Nr GL</th>
                                                    <th>Nazwa</th>
                                                    <th className={styles.th3}>Cena komponentu</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {order.components.map((component: any, componentIndex: number) => (
                                                    <tr key={componentIndex}>
                                                        <td>{component.nameOne}</td>
                                                        <td>{component.nameTwo}</td>
                                                        <td className={styles.td3}>{component.pricingSum} zł</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}

export default ValuationsList;