import React from 'react';
import styles from './styles.module.scss';

interface SelectComponentTypeProps {
    options: { id: number; name: string }[];
    value: number | null;
    onChange: (id: number | null) => void; // Zmieniono typ na `number | null`
}

const SelectComponentType: React.FC<SelectComponentTypeProps> = ({ options, value, onChange }) => {
    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = e.target.value;
        onChange(selectedValue === '' ? null : Number(selectedValue));
    };

    return (
        <div className={styles.selectComponentType}>
            <label>Wybierz typ komponentu</label>
            <select value={value ?? ''} onChange={handleChange}>
                <option value="">-- Wybierz --</option>
                {options.map((option) => (
                    <option key={option.id} value={option.id}>
                        {option.name}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default SelectComponentType;
