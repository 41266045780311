import React from 'react';
import styles from './styles.module.scss';

interface ConfirmationWindowProps {
    message: string;
    onConfirm: () => void;
    onCancel: () => void;
}

const ConfirmationWindow: React.FC<ConfirmationWindowProps> = ({ message, onConfirm, onCancel }) => {
    return (
        <div className={styles.modalConfirmationWindow}>
            <div className={styles.modalContent}>
                <p>{message}</p>
                <button onClick={onConfirm} className={styles.confirmButton}>
                    TAK
                </button>
                <button onClick={onCancel} className={styles.cancelButton}>
                    NIE
                </button>
            </div>
        </div>
    );
};

export default ConfirmationWindow;
