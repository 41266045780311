// src/hooks/useSubcomponentsWithPricing.ts
import { useMemo } from 'react';
import { usePricingContext } from '../context/PricingContext';
import { Subcomponent } from '../types/pricingTypes'; // przykładowe importy
import { SubcomponentPricing } from '../types/pricingTypes';
import { useSubcomponentPricingContext } from '../context/SubcomponentPricingContext';

interface SubcomponentWithPricing extends Subcomponent {
  pricings: SubcomponentPricing[];
}

export const useSubcomponentsWithPricing = (): SubcomponentWithPricing[] => {
   
  const { subcomponents, subcomponentPricingList } = useSubcomponentPricingContext();
  // Używamy useMemo, aby uniknąć ponownych obliczeń, jeśli dane się nie zmieniają
  const mergedData = useMemo(() => {
    return subcomponents.map((sub) => {
      const matchingPricings = subcomponentPricingList.filter(
        (price) => price.subcomponentId === sub.id
      );
      // Zwracamy wzbogacony obiekt
      return {
        ...sub,
        pricings: matchingPricings,
      };
    });
  }, [subcomponents, subcomponentPricingList]);
  return mergedData;
};
