// src/services/pricingService.ts

import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Pobieranie podkomponentów dla danego typu komponentu
export const fetchSubcomponentsByComponentType = async (componentTypeId: number) => {
    const response = await axios.get(`${API_BASE_URL}/subcomponents/${componentTypeId}`);
    return response.data;
};

// Pobieranie cenników podkomponentów według typu komponentu
export const fetchPricingBySubcomponentType = async (componentTypeId: number) => {
    const response = await axios.get(`${API_BASE_URL}/subcomponent-pricing/byComponentTypeId/${componentTypeId}`);
    return response.data;
};

// Dodawanie nowego cennika podkomponentu
export const createSubcomponentPricing = async (payload: {
    subcomponentId: number;
    repairPrice: number;
    isDiscounted: boolean;
    dateFrom: string;
}) => {
    const response = await axios.post(`${API_BASE_URL}/subcomponent-pricing`, payload);
    return response.data;  // zwróci świeżo utworzony obiekt
};

// Usuwanie cennika podkomponentu
export const removeSubcomponentPricingById = async (subcomponentPricingId: number) => {
    const response = await axios.delete(`${API_BASE_URL}/subcomponent-pricing/${subcomponentPricingId}`);
    return response.data;
};