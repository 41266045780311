import React, { useEffect, useState } from 'react';
import { usePricingContext } from '../context/PricingContext';
import { useComponentPricingContext } from '../context/ComponentPricingContext';
import StandardButton from 'components/shared/standard-button/StandardButton';
import styles from '../styles.module.scss';
import formatDate from 'utils/dateUtils';

const AddPricingModal = ({ fields, onSave, onCancel }: any) => (
  <div className={styles.modalOverlay}>
    <div className={styles.modalContent}>
      <h3>Dodaj Cennik Komponentu</h3>
      {fields.map((field, index) => (
        <div key={index} className={styles.formField}>
          <label>{field.label}</label>
          <input
            type={field.type}
            value={field.value}
            onChange={(e) =>
              field.onChange(field.type === 'number' ? Number(e.target.value) : e.target.value)
            }
          />
        </div>
      ))}
      <div style={{ marginTop: '1rem' }}>
        <StandardButton label="Zapisz" onClick={onSave} />
        <StandardButton label="Anuluj" variant="secondary" onClick={onCancel} />
      </div>
    </div>
  </div>
);

const ComponentsPricingTable = ({ componentPricingList }: any) => (
  <table className={styles.table}>
    <thead>
      <tr>
        <th>Cena Opakowania</th>
        <th>Cena Selekcji</th>
        <th>Cena Utylizacji</th>
        <th>Data Od</th>
        <th>Data Do</th>
      </tr>
    </thead>
    <tbody>
      {componentPricingList.map((item: any) => (
        <tr key={item.id}>
          <td>{item.packingPrice} PLN</td>
          <td>{item.selectionPrice} PLN</td>
          <td>{item.scrappingPrice} PLN</td>
          <td>{formatDate(item.dateFrom)}</td>
          <td>{formatDate(item.dateTo) || 'Obecnie'}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

const ComponentsPricing: React.FC = () => {
  const { selectedComponentType } = usePricingContext();
  const {
    componentPricingList,
    loadComponentPricingData,
    addComponentPricing,
    deleteComponentPricing,
  } = useComponentPricingContext();

  const [packingPrice, setPackingPrice] = useState<number>(0);
  const [selectionPrice, setSelectionPrice] = useState<number>(0);
  const [scrappingPrice, setScrappingPrice] = useState<number>(0);
  const [dateFrom, setDateFrom] = useState<string>('');
  const [isAdding, setIsAdding] = useState(false);

  useEffect(() => {
    if (selectedComponentType !== null) {
      loadComponentPricingData();
    }
  }, [selectedComponentType]);

  const handleSave = async () => {
    if (selectedComponentType === null) return;

    await addComponentPricing({
      componentTypeId: selectedComponentType,
      packingPrice,
      selectionPrice,
      scrappingPrice,
      dateFrom: new Date(dateFrom).toISOString(),
    });

    setPackingPrice(0);
    setSelectionPrice(0);
    setScrappingPrice(0);
    setDateFrom('');
    setIsAdding(false);
  };

  const normalizedPricingList = componentPricingList.map((item) => ({
    ...item,
    packingPrice: Number(item.packingPrice),
    selectionPrice: Number(item.selectionPrice),
    scrappingPrice: Number(item.scrappingPrice),
  }));

  const fields = [
    { label: 'Cena opakowania', type: 'number', value: packingPrice, onChange: setPackingPrice },
    { label: 'Cena selekcji', type: 'number', value: selectionPrice, onChange: setSelectionPrice },
    { label: 'Cena utylizacji', type: 'number', value: scrappingPrice, onChange: setScrappingPrice },
    { label: 'Data od', type: 'date', value: dateFrom, onChange: setDateFrom },
  ] as const;

  return (
    <div className={styles.wrapperSubcomponentPricingList}>
      <div className={styles.buttonRow}>
       {selectedComponentType && <StandardButton
          label='Dodaj Cennik Komponentu'
          onClick={() => setIsAdding(!isAdding)}
        />}
      </div>
      {componentPricingList.length > 0 && (
        <div className={styles.buttonRow}>
          <StandardButton label="Usuń ostatni cennik" onClick={deleteComponentPricing} />
        </div>
      )}
      {isAdding && (
        <AddPricingModal
          fields={fields}
          onSave={handleSave}
          onCancel={() => setIsAdding(false)}
        />
      )}
      <h2>Lista Cenników dla Komponentów</h2>
      {normalizedPricingList.length > 0 ? (
        <ComponentsPricingTable componentPricingList={normalizedPricingList} />
      ) : (
        <p>Brak dostępnych cenników dla tego komponentu.</p>
      )}
    </div>
  );
};

export default ComponentsPricing;
