import { Route, Routes } from "react-router-dom";
import React from "react";

// @Pages
import HomeLogin from '../pages/homelogin/HomeLogin';
import ListComponent from "../pages/listcomponent/ListComponent";
import DeliveryList from "../pages/deliverylist/DelivetyList";
import DeliveryManagement from "../pages/deliverymanagement/DeliveryManagement";
import MainMenu from '../pages/mainmenu/MainMenu';
import AdminPanel from "../pages/adminpanel/AdminPanel";
import WarehouseManagement from "../pages/warehousemanagement/WarehouseManagement"
import DeliveryAdd from '../pages/deliveryadd/DeliveryAdd'
import ComponentManagement from "pages/componentManagement/ComponentManagement";
import StatusesList from "pages/statusesList/StatusesList";
import UserPanel from "pages/userPanel/UserPanel";
import PricingPage from "pages/prycing-management/PricingPage";

export const routesData = [
    { path: '/', element: <HomeLogin /> },
    { path: '/componentlist', element: <ListComponent /> },
    { path: '/statuseslist', element: <StatusesList /> },
    { path: '/deliverylist', element: <DeliveryList /> },
    { path: '/deliverymanagement/:id', element: <DeliveryManagement /> },
    { path: '/mainmenu', element: <MainMenu /> },
    { path: '/adminpanel', element: <AdminPanel /> },
    { path: '/userpanel', element: <UserPanel /> },
    { path: '/warehousemanagement', element: <WarehouseManagement /> },
    { path: '/deliveryadd', element: <DeliveryAdd /> },
    { path: '/componentManagement/:id', element: <ComponentManagement /> },
    { path: '/prycing-management', element: <PricingPage /> },
];

const AppRoutes = (props) => {
    return (
        <Routes>
            {routesData.map((route, index) => (
                <Route
                    key={index}
                    path={route.path}
                    element={React.cloneElement(route.element, {
                        setLoggedUser: props.setLoggedUser,
                        loggedUser: props.loggedUser,
                        access: props.access,
                    })}
                />
            ))}
        </Routes>
    );
};

export default AppRoutes;
