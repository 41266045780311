import React, { createContext, useContext, useState, ReactNode } from 'react';
import styles from './styles.module.scss';
import { NotificationType } from './NotificationTypes';

type NotificationContextType = {
  addNotification: (message: string, type: NotificationType['type']) => void;
};

const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

export const NotificationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [notifications, setNotifications] = useState<NotificationType[]>([]);

  const addNotification = (message: string, type: NotificationType['type']) => {
    const id = Math.random().toString(36).substring(2, 15);
    setNotifications((prev) => [...prev, { id, message, type }]);

    setTimeout(() => {
      setNotifications((prev) => prev.filter((n) => n.id !== id));
    }, 4000);
  };

  return (
    <NotificationContext.Provider value={{ addNotification }}>
      {children}
      <div className={styles.notificationContainer}>
        {notifications.map((notification) => (
          <div key={notification.id} className={`${styles.notification} ${styles[notification.type]}`}>
            {notification.message}
          </div>
        ))}
      </div>
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotification must be used within NotificationProvider');
  }
  return context;
};
