export const listMainMenu = [
    {
        label: 'Panel Administratora',
        roles: ['admin'],
        visible: true,
        destinationURL: '/adminpanel'
    },
    {
        label: 'Panel Użytkownika',
        roles: ['admin', 'manager', 'leader', 'employee'],
        visible: true,
        destinationURL: '/userpanel'
    },
    {
        label: 'Dodaj Dostawę',
        roles: ['admin', 'manager', 'leader', 'employee'],
        visible: true,
        destinationURL: '/deliveryadd'
    },
    {
        label: 'Lista Dostaw',
        roles: ['admin', 'manager', 'leader', 'employee'],
        visible: true,
        destinationURL: '/deliverylist'
    },
    {
        label: 'Lista Komponentów',
        roles: ['admin', 'manager', 'leader', 'employee'],
        visible: true,
        destinationURL: '/componentlist'
    },
    {
        label: 'Magazyn',
        roles: ['admin', 'manager', 'leader', 'employee'],
        visible: false,
        destinationURL: '/warehousemanagement'
    },
    {
        label: 'Lista Statusów',
        roles: ['admin', 'manager', 'leader', 'employee'],
        visible: true,
        destinationURL: '/statuseslist'
    },
    {
        label: 'Zarządzanie Cennikami',
        roles: ['admin'],
        visible: true,
        destinationURL: '/prycing-management'
    },
]