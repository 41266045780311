// src/context/PricingContext.tsx

import { useNotification } from 'contexts/notification-provider/NotificationProvider';
import { usePricingContext } from './PricingContext'; // Import głównego kontekstu

import React, { createContext, useContext, useState, useEffect } from 'react';
import {
    fetchSubcomponentsByComponentType,
    fetchPricingBySubcomponentType,
    createSubcomponentPricing,
    removeSubcomponentPricingById
} from '../services/subcpomponentPricingService';

import {
    Subcomponent,
    SubcomponentPricing,
    CreateSubcomponentPricingPayload,
} from '../types/subcomponentsPricingTypes';

interface SubcomponentPricingContextProps {
    subcomponents: Subcomponent[];
    subcomponentPricingList: SubcomponentPricing[];
    selectedSubcomponent: number | null;
    setSelectedSubcomponent: (id: number) => void;
    loadSubcomponents: (componentTypeId: number) => void;
    loadSubcomponentPricingByComponentType: (componentTypeId: number) => void;
    addSubcomponentPricing: (payload: CreateSubcomponentPricingPayload) => Promise<void>;
    deleteSubcomponentPricing: (pricingId: number) => Promise<void>;
    loading: boolean;
}

// Inicjalizacja kontekstu
const SubcomponentPricingContext = createContext<SubcomponentPricingContextProps | undefined>(undefined);

export const SubcomponentPricingProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {

    const { 
        selectedComponentType,
        awaitConfirmation // Funkcja potwierdzenia z głównego kontekstu
    } = usePricingContext();
    const [subcomponents, setSubcomponents] = useState<Subcomponent[]>([]);
    const [subcomponentPricingList, setSubcomponentPricingList] = useState<SubcomponentPricing[]>([]);
    const [selectedSubcomponent, setSelectedSubcomponent] = useState<number | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    // Wywołanie hooka do powiadomień
    const { addNotification } = useNotification(); // Wywołaj hook tutaj

    // Funkcja do pobierania podkomponentów
    const loadSubcomponents = async (componentTypeId: number) => {
        setLoading(true);
        try {
            const data = await fetchSubcomponentsByComponentType(componentTypeId);
            setSubcomponents(data);
        } catch (error) {
            console.error('Błąd podczas pobierania podkomponentów:', error);
        } finally {
            setLoading(false);
        }
    };

    // Funkcja do pobierania cenników podkomponentów
    const loadSubcomponentPricingByComponentType = async (componentTypeId: number) => {
        setLoading(true);
        try {
            const data = await fetchPricingBySubcomponentType(componentTypeId);
            setSubcomponentPricingList(data);
        } catch (error) {
            console.error('Błąd podczas pobierania cenników podkomponentów:', error);
        } finally {
            setLoading(false);
        }
    };

    /**
         * Funkcja do stworzenia nowego cennika dla podkomponentu
         * @param payload 
         * @returns 
         */
    const addSubcomponentPricing = async (payload: CreateSubcomponentPricingPayload): Promise<void> => {
        if (selectedComponentType === null) return;

        setLoading(true);
        try {
            // 1) Stwórz nowy cennik w bazie:
            await createSubcomponentPricing(payload);

            // 2) Ponownie pobierz subkomponenty (bo ktoś inny mógł coś zmienić w bazie):
            const subcomps = await fetchSubcomponentsByComponentType(selectedComponentType);
            setSubcomponents(subcomps);

            // 3) Pobierz cenniki subkomponentów:
            const pricings = await fetchPricingBySubcomponentType(selectedComponentType);
            setSubcomponentPricingList(pricings);

        } catch (error) {
            console.error('Błąd podczas tworzenia cennika', error);
        } finally {
            setLoading(false);
        }
    };

    /**
         * Funkcja usunięcia cennika
         * @param payload 
         * @returns 
         */
    const deleteSubcomponentPricing = async (pricingId: number) => {
        if (selectedComponentType === null) return;

        // Wywołaj potwierdzenie z głównego kontekstu
        const userConfirmed = await awaitConfirmation();

        if (!userConfirmed) {
            // Jeśli użytkownik kliknął "NIE", przerwij działanie
            return;
        }

        // Kontynuuj, jeśli użytkownik kliknął "TAK"
        setLoading(true);
        try {
            await removeSubcomponentPricingById(pricingId);
            addNotification('Cennik dla komponentu został pomyślnie usunięty!', 'success');
        } catch (error) {
            addNotification('Błąd podczas usuwania cennika komponentu.', 'error');
        } finally {
            setLoading(false);
        }
    };

    return (
        <SubcomponentPricingContext.Provider
            value={{
                subcomponents,
                subcomponentPricingList,
                selectedSubcomponent,
                setSelectedSubcomponent,
                loadSubcomponents,
                loadSubcomponentPricingByComponentType,
                addSubcomponentPricing,
                deleteSubcomponentPricing,
                loading,
            }}
        >
            {children}
        </SubcomponentPricingContext.Provider>
    );
};

export const useSubcomponentPricingContext = () => {
    const context = useContext(SubcomponentPricingContext);
    if (!context) {
        throw new Error('useSubcomponentPricingContext musi być użyty wewnątrz SubcomponentPricingContext');
    }
    return context;
};
