import React, { useState } from 'react';
import ComponentsPricing from '../views/ComponentsPricing';
import SubcomponentsPricingList from '../views/SubcomponentsPricing';
import StandardButton from 'components/shared/standard-button/StandardButton';
import SelectComponentType from '../components/select-component-type/SelectComponentType';
import { usePricingContext } from '../context/PricingContext';
import styles from '../styles.module.scss'
import ValuationsList from '../views/ValuationsList';
import { ComponentPricingProvider } from '../context/ComponentPricingContext';
import { SubcomponentPricingProvider } from '../context/SubcomponentPricingContext';
import ConfirmationWindow from '../components/confirmation-window/ConfirmationWindow';

const PricingDashboard: React.FC = () => {

    const {
        componentTypes,
        selectedComponentType,
        setSelectedComponentType,
        showConfirmation,
        confirmationMessage,
        onConfirm,
        onCancel,
    } = usePricingContext();

    const buttonOptions = [
        { label: 'Historia Cennika Komponentów', value: 'components' },
        { label: 'Historia Cennika Podkomponentów', value: 'subcomponents' },
        { label: 'Lista Wycen', value: 'valuations' },
    ];

    const [showComponentPricing, setShowComponentPricing] = useState<boolean>(false);
    const [showSubcomponentPricing, setShowSubcomponentPricing] = useState<boolean>(false);
    const [showValuationsList, setShowValuationsList] = useState<boolean>(false);

    // Funkcja do ustawienia stanu na podstawie kliknięcia przycisku
    const handleButtonClick = (value: string) => {
        if (value === 'components') {
            setShowComponentPricing(true);
            setShowSubcomponentPricing(false);
            setShowValuationsList(false);
        } else if (value === 'subcomponents') {
            setShowComponentPricing(false);
            setShowSubcomponentPricing(true);
            setShowValuationsList(false);
        } else {
            setShowComponentPricing(false);
            setShowSubcomponentPricing(false);
            setShowValuationsList(true);
        }
    };

    return (
        <div className={styles.wrapperPricingDashoboard}>
            <h1>Zarządzanie Cennikami</h1>
            <SelectComponentType
                options={componentTypes}
                value={selectedComponentType}
                onChange={setSelectedComponentType}
            />

            {/* Mapowanie przycisków na podstawie tablicy */}
            <div className={styles.buttonSection}>
                {buttonOptions.map((button, index) => (
                    <StandardButton
                        key={index}
                        label={button.label}
                        onClick={() => handleButtonClick(button.value)}
                    />
                ))}
            </div>

            {showConfirmation && (
                <ConfirmationWindow
                    message={confirmationMessage}
                    onConfirm={onConfirm || (() => { })}
                    onCancel={onCancel || (() => { })}
                />
            )}
            
            {/* Renderowanie komponentów w zależności od wybranego stanu */}
            {showComponentPricing && (
                <ComponentPricingProvider>
                    <ComponentsPricing />
                </ComponentPricingProvider>
            )}
            {showSubcomponentPricing && (
                <SubcomponentPricingProvider>
                    <SubcomponentsPricingList />
                </SubcomponentPricingProvider>)}
            {showValuationsList && <ValuationsList />}
        </div>
    );
};

export default PricingDashboard;