import React from 'react';
import styles from './styles.module.scss';

interface StandardButtonProps {
    label: string;
    onClick: () => void;
    type?: 'button' | 'submit' | 'reset';
    variant?: 'primary' | 'secondary' | 'danger';
    disabled?: boolean;
}

const StandardButton: React.FC<StandardButtonProps> = ({
    label,
    onClick,
    type = 'button',
    variant = 'primary',
    disabled = false,
}) => {
    return (
        <button
            className={`${styles.button} ${styles[variant]}`}
            onClick={onClick}
            type={type}
            disabled={disabled}
        >
            {label}
        </button>
    );
};

export default StandardButton;
