import React, { useContext } from 'react';

//@Hooks
import UseComponentManagement from './UseComponentManagement';

//@Styles
import styles from './styles.module.scss';
import formatDate from 'utils/dateUtils';
import { UserContext } from 'contexts/LoggedUser';
import { checkUserRole } from 'utils/authUtils';
import AccessDenied from 'components/shared/accessDenied/AccessDenied';

function ComponentManagement() {
    const {
        tableConfig,
        componentData,
        subcomponentData,
        componentTypeId,
        changeSubcomponentStatus,
        scrapForm,
        handleScrapSubbmit,
        handleScrapComponentChange,
        handleOrderSubmit,
        handleDataOrderSubmit,
        handleWzNumberSubmit,
        isEditSubcomponentsMode,
        setEditSubcomponentsMode,
        isEditIssMode,
        setEditIssMode,
        isEditActivitiesMode,
        setEditActivitiesMode,
        formData,
        handleFormChange,
        handleFormSubmit,
        errorCorrectComponent,
        subcomponentConfig,
    } = UseComponentManagement();

    const { loggedUser } = useContext(UserContext);
    const allowedRoles = ["admin", "manager", "leader", "employee"];
    const isAuthorized = checkUserRole(loggedUser, allowedRoles);

    const allowedRolesToChangeStatus = ["admin", "manager", "leader"];
    const isAuthorizedChangeStatus = checkUserRole(loggedUser, allowedRolesToChangeStatus);

    const allowedRolesToCorrectDataComponent = ["admin", "manager", "leader"];
    const isAuthorizedCorrectDataComponent = checkUserRole(loggedUser, allowedRolesToCorrectDataComponent);

    let canBeUpdated = true; // Domyślnie true
    console.log("Can be updated:", canBeUpdated);
    if (!isAuthorized) {
        return <AccessDenied />;
    }
    else if (loggedUser && isAuthorized) {
        return (
            <div className={styles.wrapper}>
                {/* PANEL DANYCH KOMPONENTU */}
                {componentData && componentData.map(data => (
                    <div key={data.id} className={styles.componentDataWrapper}>
                        <div className={styles.column1}>
                            <div>Numer wewnętrzny: {data.insideNumber}</div>
                            <div>Typ komponentu: {data.componentType.name}</div>
                            <div>Dodane przez: {data.createdByUser.username}</div>
                            <div>Nr "GL": {data.nameOne}</div>
                            <div>Nazwa: {data.nameTwo === null ? 'BRAK' : data.nameTwo}</div>
                        </div>
                        <div className={styles.column2}>
                            <div>Data produkcji: {formatDate(data.productionDate)}</div>
                            <div>Nowy nr mon: {data.newMonNumber}</div>
                            <div>Stary nr mon: {data.oldMonNumber === null ? 'BRAK' : data.oldMonNumber}</div>
                            <div>Zmodyfikowane przez: {data.modifiedByUser.username}</div>
                            <div>Ostatnia modyfikacja: {data.lastModified === null ? 'BRAK' : formatDate(data.lastModified)}</div>
                        </div>
                        <div className={styles.column3}>
                            <div>Nr dostawy: {data.delivery.number}</div>
                            <div>Data dodania: {data.creationDate === null ? 'BRAK' : formatDate(data.creationDate)}</div>
                            <div>Data dostawy: {data.delivery.deliveryDate === null ? 'BRAK' : formatDate(data.delivery.deliveryDate)}</div>
                            <div>Data kontroli: {data.controlDate === null ? 'BRAK' : formatDate(data.controlDate)}</div>
                            <div>Nr zamówienia: {data.outsideNumber === null ? 'BRAK' : data.outsideNumber}</div>
                        </div>
                        <div className={styles.column4}>
                            <div>Status: {data.status.name}</div>
                            <div>Magazyn: {data.warehouse.name}</div>
                            <div>Pozycja: {data.warehousePosition.name}</div>
                            <div>Data złomowania: {data.scrappedAt === null ? 'BRAK' : formatDate(data.scrappedAt)}</div>
                            <div>Data wysyłki: {data.shippingDate === null ? 'BRAK' : formatDate(data.shippingDate)}</div>
                        </div>
                    </div>
                ))}

                {/* PANEL PODKOMPONENTÓW */}

                {tableConfig && (tableConfig as any).subcomponents[0] && (
                    <div className={styles.subcomponentsWrapper}>
                        {isAuthorizedChangeStatus && !isEditSubcomponentsMode && <button className={styles.btn} onClick={() => setEditSubcomponentsMode(true)}>Tryb Edycji</button>}
                        {isAuthorizedChangeStatus && isEditSubcomponentsMode && <button className={styles.btn} onClick={() => setEditSubcomponentsMode(false)}>Tryb Pracy</button>}
                        <h3>Panel Podkomponentów</h3>
                        <table>
                            <thead className={styles.tHead}>
                                <tr className={styles.headTr}>
                                    {(tableConfig as any).subcomponents.map((element, index) => (
                                        <th key={index}>{element.name}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className={styles.tBody}>
                                <tr>
                                    {(tableConfig as any).subcomponents.map((element, index) => {
                                        const subcomponent = subcomponentData.find(sub => sub.name === element.name);

                                        // Sprawdzenie, czy którykolwiek podkomponent ma status "NOK"
                                        if (subcomponent?.status.name === "NOK") {
                                            canBeUpdated = false;
                                            console.log("Can be updated:", canBeUpdated);
                                        }

                                        console.log(subcomponent);
                                        return (
                                            <td
                                                key={index}
                                                className={
                                                    subcomponent ? (
                                                        subcomponent.status.name === "TBC" ? styles.tbc :
                                                            subcomponent.status.name === "OK" ? styles.ok :
                                                                subcomponent.status.name === "N/A" ? styles.na :
                                                                    subcomponent.status.name === "NOK" ? styles.nok :
                                                                        subcomponent.status.name === "NAPR" ? styles.napr :
                                                                            subcomponent.status.name === "WPHJ" ? styles.wphj : null
                                                    ) : null
                                                }
                                            >
                                                {subcomponent ? (
                                                    <>
                                                        {subcomponent.status.name} <br />
                                                        {!isEditSubcomponentsMode ? (
                                                            subcomponentConfig.map((element) => {
                                                                return element.subcomponentName === subcomponent.name ? (
                                                                    <button
                                                                        className={
                                                                            !element.secondaryStatus ? styles.noAction :
                                                                                subcomponent.status.name === "TBC" ? styles.noAction :
                                                                                    subcomponent.status.name === "OK" ? styles.noAction :
                                                                                        subcomponent.status.name === "N/A" ? styles.noAction :
                                                                                            subcomponent.status.name === "NOK" ? styles.action :
                                                                                                subcomponent.status.name === "NAPR" ? styles.noAction :
                                                                                                    subcomponent.status.name === "WPHJ" ? styles.wphj : null
                                                                        }
                                                                        onClick={() => changeSubcomponentStatus(subcomponent.id, element.secondaryStatus.id)}
                                                                        disabled={
                                                                            !element.secondaryStatus ||
                                                                            subcomponent.status.name === "TBC" ||
                                                                            subcomponent.status.name === "OK" ||
                                                                            subcomponent.status.name === "N/A" ||
                                                                            subcomponent.status.name === "NAPR" ||
                                                                            subcomponent.status.name === "WPHJ"
                                                                        }
                                                                    >
                                                                        {element.secondaryStatus ? element.secondaryStatus.name : "Zablokowany"}
                                                                    </button>
                                                                ) : null;
                                                            })
                                                        ) : (
                                                            <select
                                                                className={styles.select}
                                                                onChange={(e) => changeSubcomponentStatus(subcomponent.id, parseInt(e.target.value))}
                                                            >
                                                                <option value={9999}>Wybierz opcję</option>
                                                                {subcomponentConfig.map((element) => (
                                                                    element.subcomponentName === subcomponent.name ? (
                                                                        <>
                                                                            {element.primaryStatuses.map((status) => (
                                                                                <option
                                                                                    key={status.id}
                                                                                    value={status.id}
                                                                                    className={
                                                                                        status.name === "TBC" ? styles.tbc :
                                                                                            status.name === "OK" ? styles.ok :
                                                                                                status.name === "N/A" ? styles.na :
                                                                                                    status.name === "NOK" ? styles.nok :
                                                                                                        status.name === "NAPR" ? styles.napr :
                                                                                                            status.name === "WPHJ" ? styles.wphj : null
                                                                                    }
                                                                                >
                                                                                    {status.name}
                                                                                </option>
                                                                            ))}
                                                                            {element.secondaryStatus && (
                                                                                <option
                                                                                    key={element.secondaryStatus.id}
                                                                                    value={element.secondaryStatus.id}
                                                                                    className={
                                                                                        element.secondaryStatus.name === "TBC" ? styles.tbc :
                                                                                            element.secondaryStatus.name === "OK" ? styles.ok :
                                                                                                element.secondaryStatus.name === "N/A" ? styles.na :
                                                                                                    element.secondaryStatus.name === "NOK" ? styles.nok :
                                                                                                        element.secondaryStatus.name === "NAPR" ? styles.napr :
                                                                                                            element.secondaryStatus.name === "WPHJ" ? styles.wphj : null
                                                                                    }
                                                                                >
                                                                                    {element.secondaryStatus.name}
                                                                                </option>
                                                                            )}
                                                                        </>
                                                                    ) : null
                                                                ))}
                                                            </select>
                                                        )}
                                                    </>
                                                ) : null}
                                            </td>
                                        );
                                    })}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}

                {/* PANEL ISS */}
                {tableConfig && (tableConfig as any).iss[0] && (
                    <div className={styles.subcomponentsWrapper}>
                        {isAuthorizedChangeStatus && !isEditIssMode && <button className={styles.btn} onClick={() => setEditIssMode(true)}>Tryb Edycji</button>}
                        {isAuthorizedChangeStatus && isEditIssMode && <button className={styles.btn} onClick={() => setEditIssMode(false)}>Tryb Pracy</button>}
                        <h3>Panel ISS</h3>
                        <table>
                            <thead className={styles.tHead}>
                                <tr className={styles.headTr}>
                                    {(tableConfig as any).iss.map((element, index) => (
                                        <th key={index}>{element.name}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className={styles.tBody}>
                                <tr>
                                    {(tableConfig as any).iss.map((element, index) => {
                                        const subcomponent = subcomponentData.find(sub => sub.name === element.name && sub.isIss);

                                        // Sprawdzenie, czy którykolwiek podkomponent ma status "NOK"
                                        if (subcomponent?.status.name === "NOK") {
                                            canBeUpdated = false;
                                            console.log("Can be updated:", canBeUpdated);
                                        }

                                        return (
                                            <td key={index} className={
                                                subcomponent ? (
                                                    subcomponent.status.name === "TBC" ? styles.tbc :
                                                        subcomponent.status.name === "OK" ? styles.ok :
                                                            subcomponent.status.name === "N/A" ? styles.na :
                                                                subcomponent.status.name === "NOK" ? styles.nok :
                                                                    subcomponent.status.name === "NAPR" ? styles.napr :
                                                                        subcomponent.status.name === "WPHJ" ? styles.wphj : null

                                                ) : null
                                            }>
                                                {subcomponent ? (
                                                    <>
                                                        {subcomponent.status.name} <br />
                                                        {!isEditIssMode ? (
                                                            subcomponentConfig.map((element) => {
                                                                return element.subcomponentName === subcomponent.name ? (
                                                                    <button
                                                                        className={
                                                                            !element.secondaryStatus ? styles.noAction :
                                                                                subcomponent.status.name === "TBC" ? styles.noAction :
                                                                                    subcomponent.status.name === "OK" ? styles.noAction :
                                                                                        subcomponent.status.name === "N/A" ? styles.noAction :
                                                                                            subcomponent.status.name === "NOK" ? styles.action :
                                                                                                subcomponent.status.name === "NAPR" ? styles.noAction :
                                                                                                    subcomponent.status.name === "WPHJ" ? styles.wphj : null
                                                                        }
                                                                        onClick={() => changeSubcomponentStatus(subcomponent.id, element.secondaryStatus.id)}
                                                                        disabled={
                                                                            !element.secondaryStatus ||
                                                                            subcomponent.status.name === "TBC" ||
                                                                            subcomponent.status.name === "OK" ||
                                                                            subcomponent.status.name === "N/A" ||
                                                                            subcomponent.status.name === "NAPR" ||
                                                                            subcomponent.status.name === "WPHJ"
                                                                        }
                                                                    >
                                                                        {element.secondaryStatus ? element.secondaryStatus.name : "Zablokowany"}
                                                                    </button>
                                                                ) : null;
                                                            })
                                                        ) : (
                                                            <select
                                                                className={styles.select}
                                                                onChange={(e) => changeSubcomponentStatus(subcomponent.id, parseInt(e.target.value))}
                                                            >
                                                                <option value={9999}>Wybierz opcję</option>
                                                                {subcomponentConfig.map((element) => (
                                                                    element.subcomponentName === subcomponent.name ? (
                                                                        <>
                                                                            {element.primaryStatuses.map((status) => (
                                                                                <option
                                                                                    key={status.id}
                                                                                    value={status.id}
                                                                                    className={
                                                                                        status.name === "TBC" ? styles.tbc :
                                                                                            status.name === "OK" ? styles.ok :
                                                                                                status.name === "N/A" ? styles.na :
                                                                                                    status.name === "NOK" ? styles.nok :
                                                                                                        status.name === "NAPR" ? styles.napr :
                                                                                                            status.name === "WPHJ" ? styles.wphj : null
                                                                                    }
                                                                                >
                                                                                    {status.name}
                                                                                </option>
                                                                            ))}
                                                                            {element.secondaryStatus && (
                                                                                <option
                                                                                    key={element.secondaryStatus.id}
                                                                                    value={element.secondaryStatus.id}
                                                                                    className={
                                                                                        element.secondaryStatus.name === "TBC" ? styles.tbc :
                                                                                            element.secondaryStatus.name === "OK" ? styles.ok :
                                                                                                element.secondaryStatus.name === "N/A" ? styles.na :
                                                                                                    element.secondaryStatus.name === "NOK" ? styles.nok :
                                                                                                        element.secondaryStatus.name === "NAPR" ? styles.napr :
                                                                                                            element.secondaryStatus.name === "WPHJ" ? styles.wphj : null
                                                                                    }
                                                                                >
                                                                                    {element.secondaryStatus.name}
                                                                                </option>
                                                                            )}
                                                                        </>
                                                                    ) : null
                                                                ))}
                                                            </select>
                                                        )}
                                                    </>
                                                ) : null}
                                            </td>
                                        );
                                    })}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}

                {/* PANEL AKTYWNOŚCI */}
                {tableConfig && (tableConfig as any).activities[0] && (
                    <div className={styles.subcomponentsWrapper}>
                        {isAuthorizedChangeStatus && !isEditActivitiesMode && <button className={styles.btn} onClick={() => setEditActivitiesMode(true)}>Tryb Edycji</button>}
                        {isAuthorizedChangeStatus && isEditActivitiesMode && <button className={styles.btn} onClick={() => setEditActivitiesMode(false)}>Tryb Pracy</button>}
                        <h3>Panel Aktywności</h3>
                        <table>
                            <thead className={styles.tHead}>
                                <tr className={styles.headTr}>
                                    {(tableConfig as any).activities.map((element, index) => (
                                        <th key={index}>{element.name}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className={styles.tBody}>
                                <tr>
                                    {(tableConfig as any).activities.map((element, index) => {
                                        const subcomponent = subcomponentData.find(sub => sub.name === element.name && sub.isActivity);

                                        // Sprawdzenie, czy którykolwiek podkomponent ma status "NOK"
                                        if (subcomponent?.status.name === "NOK") {
                                            canBeUpdated = false;
                                            console.log("Can be updated:", canBeUpdated);
                                        }

                                        return (
                                            <td key={index} className={
                                                subcomponent ? (
                                                    subcomponent.status.name === "TBC" ? styles.tbc :
                                                        subcomponent.status.name === "OK" ? styles.ok :
                                                            subcomponent.status.name === "N/A" ? styles.na :
                                                                subcomponent.status.name === "NOK" ? styles.nok :
                                                                    subcomponent.status.name === "NAPR" ? styles.napr :
                                                                        subcomponent.status.name === "WPHJ" ? styles.wphj : null

                                                ) : null
                                            }>
                                                {subcomponent ? (
                                                    <>
                                                        {subcomponent.status.name} <br />
                                                        {!isEditActivitiesMode ? (
                                                            subcomponentConfig.map((element) => {
                                                                return element.subcomponentName === subcomponent.name ? (
                                                                    <button
                                                                        className={
                                                                            !element.secondaryStatus ? styles.noAction :
                                                                                subcomponent.status.name === "TBC" ? styles.noAction :
                                                                                    subcomponent.status.name === "OK" ? styles.noAction :
                                                                                        subcomponent.status.name === "N/A" ? styles.noAction :
                                                                                            subcomponent.status.name === "NOK" ? styles.action :
                                                                                                subcomponent.status.name === "NAPR" ? styles.noAction :
                                                                                                    subcomponent.status.name === "WPHJ" ? styles.wphj : null
                                                                        }
                                                                        onClick={() => changeSubcomponentStatus(subcomponent.id, element.secondaryStatus.id)}
                                                                        disabled={
                                                                            !element.secondaryStatus ||
                                                                            subcomponent.status.name === "TBC" ||
                                                                            subcomponent.status.name === "OK" ||
                                                                            subcomponent.status.name === "N/A" ||
                                                                            subcomponent.status.name === "NAPR" ||
                                                                            subcomponent.status.name === "WPHJ"
                                                                        }
                                                                    >
                                                                        {element.secondaryStatus ? element.secondaryStatus.name : "Zablokowany"}
                                                                    </button>
                                                                ) : null;
                                                            })
                                                        ) : (
                                                            <select
                                                                className={styles.select}
                                                                onChange={(e) => changeSubcomponentStatus(subcomponent.id, parseInt(e.target.value))}
                                                            >
                                                                <option value={9999}>Wybierz opcję</option>
                                                                {subcomponentConfig.map((element) => (
                                                                    element.subcomponentName === subcomponent.name ? (
                                                                        <>
                                                                            {element.primaryStatuses.map((status) => (
                                                                                <option
                                                                                    key={status.id}
                                                                                    value={status.id}
                                                                                    className={
                                                                                        status.name === "TBC" ? styles.tbc :
                                                                                            status.name === "OK" ? styles.ok :
                                                                                                status.name === "N/A" ? styles.na :
                                                                                                    status.name === "NOK" ? styles.nok :
                                                                                                        status.name === "NAPR" ? styles.napr :
                                                                                                            status.name === "WPHJ" ? styles.wphj : null
                                                                                    }
                                                                                >
                                                                                    {status.name}
                                                                                </option>
                                                                            ))}
                                                                            {element.secondaryStatus && (
                                                                                <option
                                                                                    key={element.secondaryStatus.id}
                                                                                    value={element.secondaryStatus.id}
                                                                                    className={
                                                                                        element.secondaryStatus.name === "TBC" ? styles.tbc :
                                                                                            element.secondaryStatus.name === "OK" ? styles.ok :
                                                                                                element.secondaryStatus.name === "N/A" ? styles.na :
                                                                                                    element.secondaryStatus.name === "NOK" ? styles.nok :
                                                                                                        element.secondaryStatus.name === "NAPR" ? styles.napr :
                                                                                                            element.secondaryStatus.name === "WPHJ" ? styles.wphj : null
                                                                                    }
                                                                                >
                                                                                    {element.secondaryStatus.name}
                                                                                </option>
                                                                            )}
                                                                        </>
                                                                    ) : null
                                                                ))}
                                                            </select>
                                                        )}
                                                    </>
                                                ) : null}
                                            </td>
                                        );
                                    })}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}

                {/* PANEL WYSYŁKI I ZŁOMOWANIA */}

                <div className={styles.actionsCorrectWrapper}>
                    <div className={styles.actionsWrapper}>
                        <h3>Panel Wysyłki i złomowania</h3>
                        <div>
                            {/* ZŁOMOWANIE */}
                            <div>
                                <label htmlFor="scrappedAt">Data złomowania: </label>
                                <input
                                    type="date"
                                    id="scrappedAt"
                                    name="scrappedAt"
                                    value={
                                        componentData &&
                                            componentData.length > 0 &&
                                            componentData[0].scrappedAt
                                            ? new Date(componentData[0].scrappedAt).toISOString().split('T')[0]
                                            : null
                                    }
                                    onChange={handleScrapComponentChange}
                                    autoComplete="off"
                                    required
                                    disabled={componentData && componentData.length > 0 && componentData[0].scrappedAt || componentData && componentData.length > 0 && componentData[0].shippingDate}
                                />
                                <button onClick={() => { handleScrapSubbmit() }}
                                    disabled={componentData && componentData.length > 0 && componentData[0].scrappedAt || !scrapForm.scrappedAt}>
                                    Złomuj
                                </button>
                            </div>

                            {/* WYSYŁKA */}
                            <div>
                                <label htmlFor="shippingDate">Data wysyłki: </label>
                                <input
                                    type="date"
                                    id="shippingDate"
                                    name="shippingDate"
                                    value={
                                        componentData &&
                                            componentData.length > 0 &&
                                            componentData[0].shippingDate
                                            ? new Date(componentData[0].shippingDate).toISOString().split('T')[0]
                                            : null
                                    }
                                    onChange={handleScrapComponentChange}
                                    autoComplete="off"
                                    required
                                    disabled={componentData && componentData.length > 0 && componentData[0].shippingDate || componentData && componentData.length > 0 && componentData[0].scrappedAt || !canBeUpdated}
                                    max={new Date().toISOString().split('T')[0]}
                                />
                                <button onClick={() => { handleDataOrderSubmit() }}
                                    disabled={componentData && componentData.length > 0 && componentData[0].shippingDate || !scrapForm.shippingDate || componentData && componentData.length > 0 && componentData[0].scrappedAt}
                                >
                                    Wyślij
                                </button>
                            </div>

                            {/* Dodanie nr WZ */}
                            <div>
                                <label htmlFor="wzNumber">Numer WZ: </label>
                                <input
                                    type="text"
                                    id="wzNumber"
                                    name="wzNumber"
                                    onChange={handleScrapComponentChange}
                                    value={componentData && componentData.length > 0 && componentData[0].wzNumber !== null ? componentData && componentData.length > 0 && componentData[0].wzNumber: null }
                                    required
                                    disabled={!canBeUpdated || componentData && componentData.length > 0 && componentData[0].wzNumber || componentData && componentData.length > 0 && componentData[0].scrappedAt || componentData && componentData.length > 0 && !componentData[0].shippingDate}
                                />
                                <button onClick={() => { handleWzNumberSubmit() }}
                                    disabled={componentData && componentData.length > 0 && componentData[0].wzNumber || !scrapForm.wzNumber}
                                >
                                    Dodaj nr WZ
                                </button>
                            </div>

                            {/* ZAMÓWIENIE */}
                            <div>
                                <label htmlFor="outsideNumber">Numer zamówienia: </label>
                                <input
                                    type="text"
                                    id="outsideNumber"
                                    name="outsideNumber"
                                    onChange={handleScrapComponentChange}
                                    value={componentData && componentData.length > 0 && componentData[0].outsideNumber !== null ? componentData && componentData.length > 0 && componentData[0].outsideNumber : null}
                                    required
                                    disabled={componentData && componentData.length > 0 && componentData[0].outsideNumber || !canBeUpdated || componentData && componentData.length > 0 && !componentData[0].shippingDate || componentData && componentData.length > 0 && !componentData[0].wzNumber}
                                />
                                <button onClick={() => { handleOrderSubmit() }}
                                    disabled={componentData && componentData.length > 0 && componentData[0].outsideNumber || !scrapForm.outsideNumber}
                                >
                                    Dodaj zamówienie
                                </button>
                            </div>

                            
                        </div>
                    </div>
                    {/* Panel Korekty Komponentu */}
                    {isAuthorizedCorrectDataComponent && (
                        <div className={styles.componenCorrectData}>
                            <h3>Panel Korekty Komponentu</h3>
                            {errorCorrectComponent && (<div className={styles.error}>{errorCorrectComponent}</div>)}
                            <form className={styles.column1} onSubmit={handleFormSubmit}>
                                {[
                                    { label: 'Nr. ,,Gl": ', name: 'nameOne', type: 'text', minLength: 13, maxLength: 13, pattern: '[^\\s]+', required: true },
                                    { label: 'Nazwa: ', name: 'nameTwo', type: 'text' },
                                    { label: 'Nr mon OLD: ', name: 'oldMonNumber', type: 'text', minLength: 6, maxLength: 6, pattern: '[^\\s]+' },
                                    { label: 'Nr mon NEW: ', name: 'newMonNumber', type: 'text', minLength: 9, maxLength: 9, pattern: '[^\\s]+', required: true },
                                    { label: 'Data Produkcji: ', name: 'productionDate', type: 'date', required: true },
                                    { label: 'Data Kontroli: ', name: 'controlDate', type: 'date', required: true },
                                    { label: 'Data Złomowania: ', name: 'scrappedAt', type: 'date' },
                                    { label: 'Data Wysyłki: ', name: 'shippingDate', type: 'date' },
                                    { label: 'Numer Zamówienia: ', name: 'outsideNumber', type: 'text' },
                                    { label: 'Numer WZ: ', name: 'wzNumber', type: 'text' },
                                    // Warunkowe dodanie pola "Rozmiar" tylko, jeśli componentType.id nie jest równe 1
                                    ...(componentTypeId === 1 ? [
                                        { label: 'Rozmiar:', name: 'size', type: 'select', options: ['1.25', '0.3'], required: true }
                                    ] : [])
                                ].map(field => (
                                    <div key={field.name}>
                                        <label htmlFor={field.name}>{field.label}</label>
                                        {field.type === 'select' ? (
                                            <select
                                                id={field.name}
                                                name={field.name}
                                                value={formData[field.name]}
                                                onChange={handleFormChange}
                                                required={field.required}
                                            >
                                                {field.options.map(option => (
                                                    <option key={option} value={option}>{option}</option>
                                                ))}
                                            </select>
                                        ) : (
                                            <input
                                                type={field.type}
                                                id={field.name}
                                                name={field.name}
                                                value={field.type === 'date' && formData[field.name] ? formData[field.name].split('T')[0] : formData[field.name]}
                                                onChange={handleFormChange}
                                                minLength={field.minLength || undefined}
                                                maxLength={field.maxLength || undefined}
                                                pattern={field.pattern || undefined}
                                                required={field.required || undefined}
                                            />
                                        )}
                                    </div>
                                ))}
                                <button type="submit">Zapisz zmiany</button>
                            </form>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
export default ComponentManagement;
