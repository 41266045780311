import React, { useContext } from 'react';
import { UserContext } from '../../contexts/LoggedUser';

// @components
import AccessDenied from 'components/shared/accessDenied/AccessDenied';
import LoadingComponent from 'components/shared/loadingcomponent/LoadingComponent';

// @styles
import styles from './styles.module.scss';

// @utils
import { checkUserRole } from '../../utils/authUtils';
import PricingDashboard from './dashboard/PricingPageDashboard';

// Import kontekstu dla modułu
import { PricingProvider } from './context/PricingContext';

const PricingPage: React.FC = () => {
    // Zabezpieczenie strony
    const { loggedUser } = useContext(UserContext);
    const allowedRoles = ["admin"];
    const isAuthorized = checkUserRole(loggedUser, allowedRoles);

    if (!isAuthorized) {
        return <AccessDenied />;
    } else if (isAuthorized && loggedUser) {
        return (
            <PricingProvider>
                <div className={styles.wrapper}>
                    <PricingDashboard />
                </div>
            </PricingProvider>
        );
    } else {
        return <LoadingComponent />;
    }
};

export default PricingPage;
