// src/context/PricingContext.tsx

import { useNotification } from 'contexts/notification-provider/NotificationProvider';
import { usePricingContext } from './PricingContext'; // Import głównego kontekstu

import React, { createContext, useContext, useState, useEffect } from 'react';
import {
    fetchPricingByComponentType,
    createComponentPricing,
    removeComponentPricingById,
} from '../services/componentPricingService';

import {
    ComponentPricing,
    CreateComponentPricingPayload,
} from '../types/componentsPricingTypes';

interface ComponentPricingContextProps {
    componentPricingList: ComponentPricing[];
    componentPricingIdToRemove: number | null;
    loadComponentPricingData: () => void;
    addComponentPricing: (payload: CreateComponentPricingPayload) => Promise<void>;
    deleteComponentPricing: () => Promise<void>;
    loading: boolean;
}

// Inicjalizacja kontekstu
const ComponentPricingContext = createContext<ComponentPricingContextProps | undefined>(undefined);

export const ComponentPricingProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
   
    const { 
            selectedComponentType,
            awaitConfirmation // Funkcja potwierdzenia z głównego kontekstu
        } = usePricingContext();
    const [componentPricingList, setComponentPricingList] = useState<ComponentPricing[]>([]);
    const [componentPricingIdToRemove, setComponentPricingIdToRemove] = useState<number | null>(null)
    const [loading, setLoading] = useState<boolean>(false);
    // Wywołanie hooka do powiadomień
    const { addNotification } = useNotification(); // Wywołaj hook tutaj

    // Funkcja do pobierania cenników komponentów
    const loadComponentPricingData = async () => {
        if (selectedComponentType !== null) {
            setLoading(true);
            try {
                const data = await fetchPricingByComponentType(selectedComponentType);
                setComponentPricingList(data);

                // Wyciągnięcie najwyższego ID
                const highestId = data.length > 0 ? Math.max(...data.map((item: { id: number }) => item.id)) : null;
                setComponentPricingIdToRemove(highestId);
            } catch (error) {
                console.error('Błąd podczas pobierania cenników komponentów:', error);
            } finally {
                setLoading(false);
            }
        }
    };

    // Funkcja do stworzenia nowego cennika dla komponentu
    const addComponentPricing = async (payload: CreateComponentPricingPayload): Promise<void> => {
        // Zakładamy, że selectedComponentType != null
        if (selectedComponentType === null) return;

        setLoading(true);
        try {
            await createComponentPricing(payload);
            await loadComponentPricingData();
            // Dodaj powiadomienie o sukcesie
            addNotification('Cennik dla komponentu został pomyślnie dodany!', 'success');
        } catch (error) {
            console.error('Błąd tworzenia cennika komponentu:', error);
            addNotification(error.response.data.message, 'error');
        } finally {
            setLoading(false);
        }
    };

    /**
     * Funkcja usunięcia cennika
     * @param payload 
     * @returns 
     */
    const deleteComponentPricing = async () => {
        if (selectedComponentType === null) return;

        // Wywołaj potwierdzenie z głównego kontekstu
        const userConfirmed = await awaitConfirmation();

        if (!userConfirmed) {
            // Jeśli użytkownik kliknął "NIE", przerwij działanie
            return;
        }

        setLoading(true);
        try {
            await removeComponentPricingById(componentPricingIdToRemove);
            await loadComponentPricingData();
            addNotification('Cennik dla komponentu został pomyślnie usunięty!', 'success');
        } catch (error) {
            addNotification('Błąd podczas tworzenia cennika komponentu.', 'error');
        } finally {
            setLoading(false);
        }
    };

    return (
        <ComponentPricingContext.Provider
            value={{
                componentPricingList,
                componentPricingIdToRemove,
                loadComponentPricingData,
                addComponentPricing,
                deleteComponentPricing,
                loading,
            }}
        >
            {children}
        </ComponentPricingContext.Provider>
    );
};

export const useComponentPricingContext = () => {
    const context = useContext(ComponentPricingContext);
    if (!context) {
        throw new Error('useComponentPricingContext musi być użyty wewnątrz ComponentPricingContext');
    }
    return context;
};
