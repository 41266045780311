import React, { useContext, useEffect, useState } from 'react';
import { useSubcomponentsWithPricing } from '../hooks/useSubcomponentsWithPricing';
import { usePricingContext } from '../context/PricingContext';
import formatDate from 'utils/dateUtils';
import StandardButton from 'components/shared/standard-button/StandardButton';
import { checkUserRole } from 'utils/authUtils';
import { UserContext } from 'contexts/LoggedUser';
import AccessDenied from 'components/shared/accessDenied/AccessDenied';

// Import stylów z SCSS
import styles from '../styles.module.scss';
import { removeSubcomponentPricingById } from '../services/pricingService';
import { useSubcomponentPricingContext } from '../context/SubcomponentPricingContext';

const SubcomponentsPricing: React.FC = () => {
    const { loggedUser } = useContext(UserContext);
    const allowedRoles = ["admin"];
    const isAuthorized = checkUserRole(loggedUser, allowedRoles);

    const {
        selectedComponentType,
    } = usePricingContext();

    const {
        loadSubcomponents,
        loadSubcomponentPricingByComponentType,
        addSubcomponentPricing,
        deleteSubcomponentPricing
    } = useSubcomponentPricingContext();

    const subcomponentsWithPricing = useSubcomponentsWithPricing();
    const [expandedIds, setExpandedIds] = useState<number[]>([]);

    useEffect(() => {
        if (selectedComponentType !== null) {
            loadSubcomponents(selectedComponentType);
            loadSubcomponentPricingByComponentType(selectedComponentType);
        }
    }, [selectedComponentType]);

    // Rozwijanie i zwijanie podkomponentów
    const toggleExpand = (id: number) => {
        setExpandedIds((prev) =>
            prev.includes(id) ? prev.filter((elem) => elem !== id) : [...prev, id]
        );
    };

    // Modal
    const [isModalOpen, setModalOpen] = useState(false);

    // Dla którego subkomponentu chcemy dodać cennik
    const [selectedSubcomponentForNewPrice, setSelectedSubcomponentForNewPrice] = useState<{
        id: number;
        name: string;
    } | null>(null);

    const handleOpenPricingForm = (id: number, name: string) => {
        setSelectedSubcomponentForNewPrice({ id, name });
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setSelectedSubcomponentForNewPrice(null);
    };

    const getHighestPricingIdToDelete = async (pricings) => {

        // Znalezienie najwyższego ID
        const highestId = Math.max(...pricings.map((p) => p.id));

        // Wywołanie metody usuwającej
        await deleteSubcomponentPricing(highestId);

        // Odświeżenie danych
        await loadSubcomponentPricingByComponentType(selectedComponentType);

        return highestId;
    };

    // Pola formularza
    const [repairPrice, setRepairPrice] = useState<number>(0);
    const [dateFrom, setDateFrom] = useState<string>('');
    if (!isAuthorized) {
        return <AccessDenied />
    } else if (isAuthorized) {
        return (
            <div className={styles.wrapperSubcomponentPricingList}>
                {/* Modal – zamiast inline style używamy klas z SCSS */}
                {isModalOpen && selectedSubcomponentForNewPrice && (
                    <div className={styles.modalOverlay}>
                        <div className={styles.modalContent}>
                            <h3>Dodaj cennik dla: {selectedSubcomponentForNewPrice.name}</h3>
                            <p>ID subkomponentu: {selectedSubcomponentForNewPrice.id}</p>

                            {/* Pola formularza */}
                            <div className={styles.formField}>
                                <label>Cena naprawy:</label>
                                <input
                                    type="number"
                                    value={repairPrice}
                                    onChange={(e) => setRepairPrice(Number(e.target.value))}
                                />
                            </div>

                            <div className={styles.formField}>
                                <label>Data od:</label>
                                <input
                                    type="date"
                                    value={dateFrom}
                                    onChange={(e) => setDateFrom(e.target.value)}
                                />
                            </div>

                            <StandardButton
                                label="Zapisz"
                                onClick={async () => {
                                    if (!selectedSubcomponentForNewPrice) return;

                                    const isDiscounted = [1, 2, 8].includes(selectedSubcomponentForNewPrice.id);

                                    await addSubcomponentPricing({
                                        subcomponentId: selectedSubcomponentForNewPrice.id,
                                        repairPrice: Number(repairPrice),
                                        dateFrom: new Date(dateFrom).toISOString(),
                                        isDiscounted: isDiscounted,
                                    });

                                    handleCloseModal();
                                }}
                            />
                            <StandardButton
                                label="Anuluj"
                                variant="secondary"
                                onClick={handleCloseModal}
                            />
                        </div>
                    </div>
                )}

                <h2>Lista Podkomponentów</h2>
                {selectedComponentType ? (
                    <ul className={styles.expandableList}>
                        {subcomponentsWithPricing.map((sub) => {
                            const isExpanded = expandedIds.includes(sub.id);
                            return (
                                <li
                                    key={sub.id}
                                    className={isExpanded ? styles.isExpanded : ''} // Dodajemy klasę warunkowo
                                >
                                    <button onClick={() => toggleExpand(sub.id)}>
                                        {isExpanded ? '▲' : '▼'} {sub.name}
                                    </button>
                                    <div
                                        className={
                                            isExpanded
                                                ? `${styles.expandedContent} ${styles.open}`
                                                : styles.expandedContent
                                        }
                                    >
                                        {sub.pricings.length > 0 ? (
                                            <div>
                                                <table className={styles.table}>
                                                    <thead>
                                                        <tr>
                                                            <th>ID cennika</th>
                                                            <th>Cena Naprawy</th>
                                                            <th>Data Od</th>
                                                            <th>Data Do</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {sub.pricings.map((p) => (
                                                            <tr key={p.id}>
                                                                <td>{p.id}</td>
                                                                <td>{p.repairPrice} PLN</td>
                                                                <td>{formatDate(p.dateFrom)}</td>
                                                                <td>{p.dateTo ? formatDate(p.dateTo) : 'Obecnie'}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                {/* Przycisk dodania cennika (gdy cenniki już istnieją) */}
                                                <StandardButton
                                                    label='Dodaj Cennik'
                                                    onClick={() => handleOpenPricingForm(sub.id, sub.name)}
                                                />
                                                {sub.pricings.length > 0 && (
                                                    <StandardButton
                                                        label="Usuń cennik"
                                                        onClick={() => getHighestPricingIdToDelete(sub.pricings)}
                                                    />
                                                )}
                                            </div>
                                        ) : (
                                            <div>
                                                <p>Brak dostępnych cenników dla tego podkomponentu.</p>
                                                <StandardButton
                                                    label='Dodaj Cennik'
                                                    onClick={() => handleOpenPricingForm(sub.id, sub.name)}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                ) : (
                    <div>
                        <p>Brak dostępnych cenników dla tego podkomponentu.</p>
                    </div>
                )}
            </div>
        );
    }
};

export default SubcomponentsPricing;
